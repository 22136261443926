import {
  Box,
  CardContent,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useContext, useMemo } from "react";
import { alphabetArray } from "../utils/alphabetArray";
import MapPickerModal from "./MapPickerModal";
import { makeRequest } from "../config/api";
import { TagContext } from "../context/TagContext";
import { SelectChangeEvent } from "@mui/material/Select";
import AddressAutoComplete from "./AddressAutoComplete";
import { useLoadScript } from "@react-google-maps/api";
import { useSnackbar } from "notistack";

const MapTagComponent = ({ tag }: { tag: any }) => {
  const { tags, setTags } = useContext(TagContext);
  const [tagGroup, setTagGroup] = React.useState("");
  const [tagNumber, setTagNumber] = React.useState("");

  const { enqueueSnackbar } = useSnackbar();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: ["places"],
  });

  useEffect(() => {
    setTagGroup(tag.groupLetter || "");
    setTagNumber(tag.groupNumber?.toString() || "");
    // setLocationAddress(tag.location?.address || "");
  }, [tag.groupLetter, tag.groupNumber, tag.location?.address]);

  const handleUpdate = async (payload: any) => {
    try {
      console.log("Updating tag:", tag._id, payload);
      const response = await makeRequest({
        method: "patch",
        endpoint: `/api/dummy/update/${tag._id}`,
        data: payload,
      });
      if (response.data?.data) {
        const updatedTags = tags.map((t) =>
          t._id === response.data.data._id ? response.data.data : t
        );
        setTags(updatedTags);
      }
    } catch (error) {
      console.error("Update failed:", error);
      enqueueSnackbar("Update failed, changes reverted", { variant: "error" });
    }
  };

  const handleGroupChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setTagGroup(value);
    handleUpdate({ groupLetter: value });
  };

  const handleNumberChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setTagNumber(value);
    handleUpdate({ groupNumber: value ? Number(value) : null });
  };

  const handleLocationChange = async (
    location: { address: string; lat: number; lng: number } | string,
    mapConfirmed = false
  ) => {
    const prevLocation = tag?.location;
    const prevTags = [...tags];
    try {
      let newLocation: { address: string; lat: number; lng: number } | null =
        null;
      // console.log("newLocation", mapConfirmed);
      if (typeof location === "string") {
        if (!window.google) {
          enqueueSnackbar("Maps API not loaded", { variant: "error" });
          return;
        }

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: location }, (results, status) => {
          if (status === "OK" && results?.[0]?.geometry) {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            const address = results[0].formatted_address;
            newLocation = { address, lat, lng };

            // Optimistic update
            const updatedTag = { ...tag, location: newLocation, mapConfirmed };
            const updatedTags = tags.map((t) =>
              t._id === tag._id ? updatedTag : t
            );
            setTags(updatedTags);
            // setLocationAddress(address);

            // Server update
            handleUpdate({ location: newLocation, mapConfirmed });
          }
        });
        return;
      }

      // Handle location object from map picker
      newLocation = location;

      // Optimistic update
      const updatedTag = { ...tag, location: newLocation, mapConfirmed };
      const updatedTags = tags.map((t) => (t._id === tag._id ? updatedTag : t));
      setTags(updatedTags);

      // Server update
      handleUpdate({ location: newLocation, mapConfirmed });
    } catch (error) {
      console.error("Location update failed:", error);
      enqueueSnackbar("Location update failed", { variant: "error" });
      // Rollback on error
      setTags(prevTags);
    }
  };

  const defaultLocation = useMemo(() => {
    // First check current tag's location
    if (tag.location?.address && tag.location.lat && tag.location.lng) {
      return tag.location;
    }

    // Then check group locations
    if (!tag.groupLetter) return undefined;
    const groupTags = tags.filter((t) => t.groupLetter === tag.groupLetter);

    return groupTags.find(
      (t) => t.location?.address && t.location.lat && t.location.lng
    )?.location;
  }, [tags, tag.groupLetter, tag.location]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 4fr",
        gap: "5px",
      }}
    >
      {/* lock if there's not address */}
      <FormControl
        variant="standard"
        sx={{ m: 1 }}
        disabled={!tag.location?.address}
      >
        <Select value={tagGroup} onChange={handleGroupChange} displayEmpty>
          <MenuItem value="">
            <em>Abc</em>
          </MenuItem>
          {alphabetArray?.map((letter) => (
            <MenuItem key={letter.value} value={letter.value}>
              {letter.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        sx={{ m: 1 }}
        disabled={!tag.location?.address}
      >
        <Select value={tagNumber} onChange={handleNumberChange} displayEmpty>
          <MenuItem value="">
            <em>123</em>
          </MenuItem>
          {Array.from({ length: 30 }, (_, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ border: "1px solid #ccc", marginBottom: "10px" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={tag.title}
            >
              {tag.title}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <AddressAutoComplete
              field={{
                onChange: (location) => handleLocationChange(location),
                value: tag.location,
              }}
              isLoaded={isLoaded}
            />
            <MapPickerModal
              onLocationSelected={(location) =>
                handleLocationChange(location, true)
              }
              defaultLocation={defaultLocation}
              isLoaded={isLoaded}
              mapConfirmed={tag?.mapConfirmed}
            />
          </Box>
        </CardContent>
        <Divider />
      </div>
    </div>
  );
};

export default MapTagComponent;
