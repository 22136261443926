import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { useSnackbar } from "notistack";

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
  lat?: number;
  lng?: number;
}

interface FieldProps {
  field: {
    onChange: (event: any) => void;
    value: any;
  };
  isLoaded: boolean;
}

export default function AddressAutoComplete({ field, isLoaded }: FieldProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState<PlaceType | null>(
    field.value
      ? {
          description: field.value.address,
          structured_formatting: {
            main_text: field?.value?.address?.split(",")[0],
            secondary_text: field?.value?.address
              ?.split(",")
              .slice(1)
              .join(",")
              .trim(),
          },
          place_id: "",
          lat: field.value.lat,
          lng: field.value.lng,
        }
      : null
  );
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any)?.getPlacePredictions(
            request,
            callback
          );
        },
        400
      ),
    []
  );

  React.useEffect(() => {
    if (!isLoaded) return;

    let active = true;
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, isLoaded]);

  const getPlaceDetails = React.useCallback(
    (placeId: string) => {
      return new Promise<{ lat: number; lng: number; address: string }>(
        (resolve, reject) => {
          if (!isLoaded || !window.google) {
            reject("Maps API not loaded");
            return;
          }

          const service = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );

          service.getDetails({ placeId }, (place, status) => {
            if (status === "OK" && place?.geometry?.location) {
              resolve({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                address: place.formatted_address || "",
              });
            } else {
              reject("Failed to get place details");
            }
          });
        }
      );
    },
    [isLoaded]
  );

  const handleChange = async (event: any, newValue: PlaceType | null) => {
    try {
      if (!newValue) {
        setValue(null);
        field.onChange(null);
        return;
      }

      const { lat, lng, address } = await getPlaceDetails(newValue.place_id);

      const fullAddress = address || newValue.description;

      const updatedValue = {
        ...newValue,
        description: fullAddress,
        lat,
        lng,
      };

      setValue(updatedValue);
      field.onChange({
        address: fullAddress,
        lat,
        lng,
      });

      setOptions(newValue ? [newValue, ...options] : options);
    } catch (error) {
      enqueueSnackbar("Failed to get location coordinates", {
        variant: "error",
      });
      console.error("Error getting place details:", error);
    }
  };

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          fullWidth
          placeholder="Add Location"
          // placeholder should red
          sx={{
            "& .MuiInputBase-input::placeholder": {
              color: "red", // Placeholder color
              opacity: 1, // Needed for Firefox
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Grid container alignItems="center">
            <Grid item sx={{ display: "flex", width: 44 }}>
              <LocationOnIcon sx={{ color: "text.secondary" }} />
            </Grid>
            <Grid
              item
              sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
            >
              <Typography variant="body1">
                {option.structured_formatting.main_text}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
}

// Rest of the code remains the same
const autocompleteService = { current: null };
