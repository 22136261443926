import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import "tailwindcss/tailwind.css"; // Add this import
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import { BASE_URL } from "../config/constants";
import EditIcon from "@mui/icons-material/Edit";
import { makeRequest } from "../config/api";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext"; // Adjust path based on your structure
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import useAuth from "../hooks/AuthHook";
import PeopleIcon from "@mui/icons-material/People";
import VisitorsCard from "./VisitorsCard";
import CustomModal from "./CustomModal";
import { formatDate } from "../utils/formatDate";
import WalkingMapModal from "./WalkingMapModal";
import { TagProvider } from "../context/TagContext";
import MyMapsModal from "./MyMapsModal";
import { useLoadScript } from "@react-google-maps/api";
import AddressAutoComplete from "./AddressAutoComplete";

export default function AccountMenu() {
  const [myTags, setMyTags] = useState([]);
  const [message, setMessage] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [fields, setFields] = useState({
    name: "",
    email: "",
    password: "",
  });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: ["places"],
  });
  const { token } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTagModalOpen, setDeleteTagModalOpen] = useState(false);
  const [tagToDelete, setTagToDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveLocation = async (id: any, value: any, location: any) => {
    const payload =
      typeof value === "string"
        ? {
            location: {
              ...location,
              address: value,
            },
          }
        : { location: value };
    console.log("Payload:", payload);
    let data = await makeRequest({
      method: "patch",
      endpoint: `/api/dummy/update/${id}`,
      data: payload,
    });
    console.log("Data:", data);
  };

  const handleUpdateProfile = async (field) => {
    if (!token) {
      enqueueSnackbar("You must be logged in to update your profile.", {
        variant: "error",
      });
      return;
    }

    try {
      const payload = { [field]: fields[field] };
      const response = await axios.put(`${BASE_URL}/api/auth/update`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        enqueueSnackbar("Profile updated successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error updating profile", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Error updating profile", { variant: "error" });
    }
  };

  const [editMode, setEditMode] = React.useState({
    name: false,
    email: false,
    password: false,
  });
  const [modalContent, setModalContent] = React.useState<
    "myTags" | "settings" | null
  >(null);
  const open = Boolean(anchorEl);
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const handleModalClose = (event: React.SyntheticEvent) => {
    // Avoid closing the modal if clicking outside the content
    if (event.target === event.currentTarget) return;
    setOpenModal(false);
    setModalContent(null);
  };

  const handleCloseButtonClick = () => {
    setOpenModal(false);
    setModalContent(null);
  };

  const handleMyTagsClick = async () => {
    setAnchorEl(null);

    if (isLoggedIn) {
      try {
        setIsDataFetched(false); // Reset fetching state
        const response = await axios.get(`${BASE_URL}/api/dummy/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setMyTags(response.data.data); // Update myTags state with fresh data
          console.log("New myTags:", response.data.data);
        } else {
          setMessage("Unexpected response status.");
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setMessage("No record found");
        } else {
          setMessage("Error fetching data");
        }
        console.error("Error fetching data:", error);
      } finally {
        setIsDataFetched(true); // Mark as data fetched
        setModalContent("myTags"); // Set modal content
        setOpenModal(true); // Open modal
      }
    } else {
      navigate("/login"); // Redirect to login if not logged in
    }
  };

  const handleSettingsClick = async () => {
    setAnchorEl(null);
    if (isLoggedIn) {
      try {
        setIsDataFetched(false); // Reset fetching state
        const response = await axios.get(`${BASE_URL}/api/auth/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setFields({
            name: response.data.user.name,
            email: response.data.user.email,
            password: response.data.user.password,
          });
          // setName(response.data.user.name);
          // setEmail(response.data.user.email);
          setModalContent("settings");
          setOpenModal(true);
        } else {
          setMessage("Unexpected response status.");
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setMessage("No record found");
        } else {
          setMessage("Error fetching data");
        }
        console.error("Error fetching data:", error);
      } finally {
        setIsDataFetched(true);
      }
    } else {
      navigate("/login");
    }
  };

  const { logout } = useAuth();

  const handleSignInOrOut = () => {
    setAnchorEl(null);
    if (isLoggedIn) {
      logout(); // This handles clearing auth state
      localStorage.removeItem("editorValue");
      localStorage.removeItem("title");
      localStorage.removeItem("subTitle");
      navigate("/login"); // Explicit navigation after logout
    } else {
      navigate("/login");
    }
  };

  const handleBoxClick = (tag) => {
    setOpenModal(false);
    navigate("/", { state: { tag: tag } });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}/api/auth/delete`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        logout(); // This handles clearing auth state
        enqueueSnackbar("Account deleted successfully", { variant: "success" });
        setDeleteModalOpen(false); // Close delete confirmation modal
        setOpenModal(false); // Close settings modal
        navigate("/"); // Explicit navigation after account deletion
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      enqueueSnackbar("Failed to delete account", { variant: "error" });
    }
    setDeleteModalOpen(false);
  };

  const handleDeleteTag = async (tagId) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/dummy/delete/${tagId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        enqueueSnackbar("Tag deleted successfully", { variant: "success" });
        setMyTags(myTags.filter((tag) => tag._id !== tagId)); // Remove tag from state
      }
    } catch (error) {
      console.error("Error deleting tag:", error);
      enqueueSnackbar("Failed to delete tag", { variant: "error" });
    }
    setDeleteTagModalOpen(false);
  };

  const renderModalContent = () => {
    if (modalContent === "myTags") {
      return (
        <Box sx={{ width: "100%" }}>
          {myTags.length > 0 ? (
            myTags.map((tag, index) => (
              <div
                key={tag._id}
                style={{
                  border: "1px solid #ccc",
                  marginBottom: "10px",
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        "&:hover": {
                          color: "#f1356d",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => handleBoxClick(tag)}
                    >
                      {tag.title}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "black",
                          "&:hover": {
                            color: "#f1356d",
                          },
                        }}
                      >
                        {formatDate(tag.createdAt)}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setTagToDelete(tag._id);
                          setDeleteTagModalOpen(true);
                        }}
                        sx={{ color: "red" }}
                      >
                        <DeleteIcon
                          sx={{
                            color: "black",
                            "&:hover": {
                              color: "#f1356d",
                            },
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <AddressAutoComplete
                      isLoaded={isLoaded}
                      field={{
                        value: tag.location || {
                          address: "",
                        },
                        onChange: (location) => {
                          // Store previous location for potential rollback
                          const prevLocation = tag?.location;
                          const prevTags = [...myTags];
                          // console.log("Prev location:", location);
                          try {
                            let newLocation = null;
                            // Handle location object from autocomplete selection
                            newLocation = location;
                            // console.log("New location:", newLocation);

                            // Server update
                            handleSaveLocation(
                              tag._id,
                              newLocation,
                              prevLocation
                            );
                          } catch (error) {
                            console.error("Location update failed:", error);
                            enqueueSnackbar("Location update failed", {
                              variant: "error",
                            });
                            // Rollback on error
                            setMyTags(prevTags);
                          }
                        },
                      }}
                    />
                  </Box>
                </CardContent>
                <Divider />
              </div>
            ))
          ) : (
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                color: "gray",
                fontSize: "16px",
                fontWeight: "bold",
                marginTop: 2,
              }}
            >
              No tags found!
            </Typography>
          )}
        </Box>
      );
    }

    if (modalContent === "settings") {
      const handleEditToggle = (field) => {
        if (editMode[field]) {
          // Save mode: Call API only on save
          handleUpdateProfile(field);
        }
        setEditMode({ ...editMode, [field]: !editMode[field] });
      };

      const handleFieldChange = (field, value) => {
        setFields({ ...fields, [field]: value });
        // console.log(fields);
      };

      return (
        <>
          <Box sx={{ width: "100%" }}>
            {Object.keys(fields).map((field) => (
              <Box key={field} sx={{ py: 2, m: 0 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 0,
                  }}
                >
                  {editMode[field] ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <TextField
                        value={fields[field]}
                        onChange={(e) =>
                          handleFieldChange(field, e.target.value)
                        }
                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                        variant="outlined"
                        fullWidth
                        sx={{ marginRight: 2 }}
                        type={
                          field === "password" && !showPassword
                            ? "password"
                            : "text"
                        }
                        InputProps={
                          field === "password"
                            ? {
                                endAdornment: (
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                ),
                              }
                            : undefined
                        }
                      />
                    </Box>
                  ) : (
                    <Typography>
                      <strong>
                        {field.charAt(0).toUpperCase() + field.slice(1)}:
                      </strong>{" "}
                      {field === "password" ? "*****" : fields[field]}
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "green",
                      ":hover": { backgroundColor: "#f1356d" },
                    }}
                    onClick={() => handleEditToggle(field)}
                    startIcon={editMode[field] ? <SaveIcon /> : <EditIcon />}
                  >
                    {editMode[field] ? "Save" : "Edit"}
                  </Button>
                </CardContent>
              </Box>
            ))}

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => setDeleteModalOpen(true)}
                size="medium"
                sx={{
                  mt: 2,
                  fontSize: "0.7rem",
                  padding: "10px 16px",
                  height: "32px",
                  width: "auto",
                  borderRadius: "4px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                Delete Account
              </Button>
            </Box>
          </Box>

          {/* Delete Confirmation Modal */}
          <CustomModal
            open={deleteModalOpen}
            setOpen={() => setDeleteModalOpen(false)}
          >
            <Typography variant="h6" sx={{ mb: 3 }}>
              Are you sure about deleting your account?
            </Typography>
            <Typography variant="body2" color="error" sx={{ mb: 3 }}>
              This action cannot be undone.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteAccount}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                sx={{ bgcolor: "green", "&:hover": { bgcolor: "#006400" } }}
                onClick={() => setDeleteModalOpen(false)}
              >
                No
              </Button>
            </Box>
          </CustomModal>
        </>
      );
    }

    return null;
  };

  const [visitorsModalOpen, setVisitorsModalOpen] = useState(false);
  const [visitors, setVisitors] = useState([]);
  const [reportsAnchorEl, setReportsAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const reportsOpen = Boolean(reportsAnchorEl);

  const handleVisitorsClick = async () => {
    setReportsAnchorEl(null);
    if (isLoggedIn) {
      try {
        const response = await axios.get(`${BASE_URL}/api/dummy/my-visitors`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log("Visitors response:", response);
        if (response.status === 200) {
          setVisitors(response.data.result);
          setVisitorsModalOpen(true);
        }
      } catch (error) {
        console.error("Error fetching visitors:", error);
        enqueueSnackbar("Failed to fetch visitors data", { variant: "error" });
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        onMouseLeave={() => setAnchorEl(null)}
      >
        <Typography
          sx={{
            minWidth: 100,
            fontWeight: "bold",
            color: "black",
            "&:hover": {
              color: "#f1356d",
              cursor: "pointer",
            },
          }}
          onClick={() => {
            localStorage.removeItem("editorValue");
            localStorage.removeItem("title");
            localStorage.removeItem("subTitle");
            if (window.location.pathname === "/") {
              window.location.reload();
            } else {
              navigate("/");
            }
          }}
        >
          2tag
        </Typography>
        <Tooltip title="My Account">
          <Typography
            onMouseEnter={(event) => setAnchorEl(event.currentTarget)} // Open on hover
            sx={{
              cursor: "pointer",
              fontWeight: "bold",
              color: "black",
              "&:hover": {
                color: "#f1356d",
              },
            }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            Account
          </Typography>
        </Tooltip>
        <Tooltip title="Reports">
          <Typography
            onMouseEnter={(event) => setReportsAnchorEl(event.currentTarget)}
            sx={{
              minWidth: 100,
              fontWeight: "bold",
              color: "black",
              cursor: "pointer",
              "&:hover": {
                color: "#f1356d",
              },
            }}
            aria-controls={reportsOpen ? "reports-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={reportsOpen ? "true" : undefined}
          >
            Reports
          </Typography>
        </Tooltip>
        <Typography
          sx={{
            minWidth: 100,
            fontWeight: "bold",
            color: "black",
            "&:hover": {
              color: "#f1356d",
              cursor: "pointer",
            },
          }}
          onClick={() => handleSignInOrOut()}
        >
          {isLoggedIn ? "Log Out" : "Login/SignUp"}
        </Typography>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          disableAutoFocusItem // Add this to prevent auto focusing first item
          keepMounted
          open={open}
          onClose={() => setAnchorEl(null)}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: 250,
                height: {
                  xs: "auto",
                  sm: "auto",
                  md: "auto",
                },
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={handleMyTagsClick}
            sx={{
              marginBottom: "10px",
              "&:hover": {
                color: "#f1356d",
              },
            }}
          >
            <ListItemIcon>
              <FileOpenIcon />
            </ListItemIcon>
            My Tags
          </MenuItem>
          <TagProvider>
            <WalkingMapModal />
          </TagProvider>

          <MyMapsModal />
          <MenuItem
            onClick={handleSettingsClick}
            sx={{
              marginBottom: "10px",
              "&:hover": {
                color: "#f1356d",
              },
            }}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
        </Menu>
        <Menu
          anchorEl={reportsAnchorEl}
          id="reports-menu"
          open={reportsOpen}
          onClose={() => setReportsAnchorEl(null)}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: 200,
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={handleVisitorsClick}
            sx={{
              "&:hover": {
                color: "#f1356d",
              },
            }}
          >
            <ListItemIcon>
              <PeopleIcon fontSize="small" />
            </ListItemIcon>
            My Visitors
          </MenuItem>
        </Menu>
      </Box>

      {/* Main Settings Modal */}
      <CustomModal
        open={openModal}
        setOpen={(event: React.SyntheticEvent) => {
          // if (event.target === event.currentTarget) return;
          setOpenModal(false);
          setModalContent(null);
        }}
        disableBackdropClick
        actionButton
        dialogTitleComponent={
          modalContent === "myTags" ? (
            <Typography
              variant="h6"
              sx={{ marginBottom: 3, fontWeight: "bold", textAlign: "center" }}
            >
              My Tags
            </Typography>
          ) : (
            <Typography
              variant="h6"
              sx={{ marginBottom: 3, fontWeight: "bold", textAlign: "center" }}
            >
              Settings
            </Typography>
          )
        }
      >
        {renderModalContent()}
      </CustomModal>

      <CustomModal
        open={deleteTagModalOpen}
        setOpen={() => setDeleteTagModalOpen(false)}
        dialogTitleComponent={
          <Typography variant="h6" sx={{ mb: 3 }}>
            This will delete the tag all it's story content
          </Typography>
        }
      >
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteTag(tagToDelete)}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            sx={{ bgcolor: "green", "&:hover": { bgcolor: "#006400" } }}
            onClick={() => setDeleteTagModalOpen(false)}
          >
            Cancel
          </Button>
        </Box>
      </CustomModal>

      <CustomModal
        actionButton
        open={visitorsModalOpen}
        setOpen={() => setVisitorsModalOpen(false)}
        dialogTitleComponent={
          <Typography
            variant="h6"
            sx={{ marginBottom: 3, fontWeight: "bold", textAlign: "center" }}
          >
            My Visitors
          </Typography>
        }
        // paperStyles={
        //   {
        //     maxWidth: "700px",
        //   }
        // }
      >
        {visitors?.length > 0 ? (
          visitors.map((tag) => <VisitorsCard key={tag._id} tag={tag} />)
        ) : (
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              color: "text.secondary",
              mt: 2,
            }}
          >
            No visitors data found
          </Typography>
        )}
      </CustomModal>
    </React.Fragment>
  );
}
