import React, { useEffect, useState } from "react";
import CustomModal from "./CustomModal";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import CheckIcon from "@mui/icons-material/Check";

interface LocationResult {
  lat: number;
  lng: number;
  address: string;
}

interface MapPickerModalProps {
  onLocationSelected: (result: LocationResult) => void;
  defaultLocation?: { lat: number; lng: number; address?: string };
  isLoaded: boolean;
  mapConfirmed?: boolean;
}

const MapPickerModal = ({
  onLocationSelected,
  defaultLocation,
  isLoaded,
  mapConfirmed,
}: MapPickerModalProps) => {
  // console.log("defaultLocation", defaultLocation);
  const [openModal, setOpenModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [address, setAddress] = useState("");
  const [center, setCenter] = useState(
    defaultLocation || { lat: 51.5074, lng: -0.1278 }
  );
  const [zoom, setZoom] = useState(14);

  // MapPickerModal.tsx
  useEffect(() => {
    if (defaultLocation) {
      setCenter(defaultLocation);
      // setZoom(17);
      setSelectedLocation({
        lat: defaultLocation.lat,
        lng: defaultLocation.lng,
      });
      setAddress(defaultLocation.address || "");
    }
  }, [defaultLocation?.lat, defaultLocation?.lng]);

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    if (!e.latLng) return;
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    setSelectedLocation({ lat, lng });
    setCenter({ lat, lng });
    setZoom(17);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results?.[0]) {
        setAddress(results[0].formatted_address);
      } else {
        setAddress("Address not found");
      }
    });
  };

  const handleConfirm = () => {
    if (selectedLocation && address) {
      onLocationSelected({
        lat: selectedLocation.lat,
        lng: selectedLocation.lng,
        address,
      });
      setOpenModal(false);
      setSelectedLocation(null);
      setAddress("");
    }
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <IconButton
        onClick={() => {
          // console.log("Default location:", defaultLocation);
          setOpenModal(true);

          if (defaultLocation) {
            setCenter(defaultLocation);
            setZoom(14);
            // console.log("Default location:", defaultLocation);
          }
          // else if (navigator.geolocation) {
          //   navigator.geolocation.getCurrentPosition(
          //     (position) => {
          //       setCenter({
          //         lat: position.coords.latitude,
          //         lng: position.coords.longitude,
          //       });
          //       setZoom(18);
          //     },
          //     () => {
          //       setCenter({ lat: 51.5074, lng: -0.1278 }); // London fallback
          //       setZoom(14);
          //     }
          //   );
          // }
          else {
            setCenter({ lat: 51.5074, lng: -0.1278 }); // London fallback
            setZoom(14);
          }
        }}
      >
        <AddLocationIcon />
      </IconButton>

      {mapConfirmed && (
        <div
          style={{
            position: "absolute",
            top: -20,
            right: 7,
          }}
        >
          <CheckIcon color="success"/>
        </div>
      )}

      <CustomModal
        open={openModal}
        setOpen={() => setOpenModal(false)}
        disableBackdropClick
        dialogTitleComponent={
          <Typography
            variant="h6"
            sx={{ marginBottom: 3, fontWeight: "bold", textAlign: "center" }}
          >
            Select Location on Map
          </Typography>
        }
        paperStyles={{
          maxWidth: "md",
        }}
      >
        <Box sx={{ height: "500px", width: "100%", mb: 2 }}>
          {!isLoaded ? (
            <Typography>Loading map...</Typography>
          ) : (
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={center}
              zoom={zoom}
              onClick={handleMapClick}
            >
              {selectedLocation !== null && (
                <MarkerF position={selectedLocation} visible />
              )}
            </GoogleMap>
          )}
        </Box>

        <Typography variant="body2" sx={{ mb: 2 }}>
          {address || "Click on the map to select a location"}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={!selectedLocation}
          >
            Confirm Location
          </Button>
        </Box>
      </CustomModal>
    </div>
  );
};

export default MapPickerModal;
